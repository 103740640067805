<template>
	<div class="footer--app">
		<hr>
		<div>
			<div>© Bildhive. 2020</div>
			<div style="color:#B0B0B3; font-size:10px">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
				commodo ligula eget dolor. Aenean massa.</div>
		</div>
	</div>
</template>

<script>

export default {
	props: {
	},
	data() {
		return {
		}
	}
}
</script>

<style>
.footer--app {
	bottom: 0px;
	margin-top: auto;
	width: 100%;
}
</style>
